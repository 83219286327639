<template>
    <div class="sup_content package-list">
        <div class="sup_page_heading">
            <h2>Package Management</h2>
            <Button v-if="packageAdd" icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-primary" v-tooltip="'Add Package'" @click="packageModal.addPackageModal = true" />
        </div>
        <div class="sup_inner_content">
            <!--- Show Package Table Start --->
            <DataTable class="p-datatable" :paginator="true" :value="allPackageData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allPackageData.loading" responsiveLayout="scroll" removableSort :filters="filters">
                <template #loading>
                    Loading Packages data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">
                            <Button v-if="packageDeactivateList" :label="allPackageData.activityStatus === 'active' ? 'Deactive List': 'Active List'" :icon="['pi', allPackageData.activityStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allPackageData.activityStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllPackages(allPackageData.activityStatus, 'packageStatus')" />
                            <Button v-if="allPackageData.activityStatus !== 'active'" label="Delete All" icon="pi pi-trash" class="p-button-danger"  />
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allPackageData.search" placeholder="Search Package" @keyup.enter="fetchAllPackages(allPackageData.activityStatus, 'search')"/>
                            </span>
                            <Calendar v-model="allPackageData.dateRange" dateFormat="dd.mm.yy" selectionMode="range" class="sup_button p-ml-2" inputClass="p-inputtext" placeholder="DD.MM.YY" :manualInput="false" :showIcon="true" @date-select="allPackageData.dateRange[1] != null ? fetchAllPackages(allPackageData.activityStatus, 'dateRange') : ''" plceholder="abc" />
                            <Button v-if="allPackageData.search !== null || allPackageData.dateRange !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllPackages(allPackageData.activityStatus, 'resetFilters')"/>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No Package found.
                </template>
                <Column field="packageName" header="Package Name" :sortable="true"></Column>

                <Column field="Packagetype" header="Package Type" :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.Packagetype.name}}
                    </template>
                </Column>

                <Column field="price" header="Monthly Price" :sortable="true">
                    <template #body="slotProps">
                        {{'$'+slotProps.data.price}}
                    </template>
                </Column>

                <Column field="hits" header="Page Views" :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.hits + ' Hits'}}
                    </template>
                </Column>

                <Column field="status" header="Status">
                    <template #body="slotProps">

                        <span :class="['sup_item_status', slotProps.data.status ? 'sup_item_status_success' : 'sup_item_status_danger']">{{slotProps.data.status ? 'Active' : 'Inactive'}}</span>

                        
                    </template>
                </Column>

                <Column field="action" header="Actions">
                    <template #body="slotProps">
                        <Button v-if="packageEdit" type="button" icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-primary p-mr-1" @click="editPackage(slotProps.data, 'editPackage')" v-tooltip="'Edit Package'" />
                        <Button v-if="packageDeactivate ? slotProps.data.status : false" type="button" icon="pi pi-ban" class="p-button-sm p-button-rounded p-button-danger p-mr-1" v-tooltip="'Deactivate'" @click="confimationModal(slotProps.data, 'delete')" />
                        <Button v-if="packageDeactivate ? !slotProps.data.status : false" icon="pi pi-undo" class="p-button-secondary p-button-rounded p-button-sm p-mr-1"  v-tooltip="'Restore'" @click="confimationModal(slotProps.data, 'restore')" />
                    </template>
                </Column>
            </DataTable>
            <!--- Show Package Table End --->
        </div>
        <!--- Add Package Modal Start --->
        <Dialog header="Add Package" v-model:visible="packageModal.addPackageModal" :modal="true" :style="{width: '800px'}"  @hide="closeModal()">
            <form class="sup_package_add" @submit.prevent="createPackage()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="p_name">Package Name*</label>
                        <InputText v-model="addEditPackage.name" id="p_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.packageName">Name is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="p_hits">Page Views*</label>
                        <Dropdown v-model="addEditPackage.histList" :options="packageModal.hitsData" id="p_hits" optionLabel="hits" optionValue="hits" placeholder="Select Page Views" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.packageHistList">Chose a page View.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="p_custom">Set as Custom package*</label>
                        <Dropdown v-model="addEditPackage.isCustom" :options="customPackage" id="p_custom" optionLabel="name" optionValue="value" placeholder="Select" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.isCustom">Must chose if custom package or not.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="p_price">Monthly Price*</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">$</span>
                            <InputText v-model="addEditPackage.price" id="p_price" type="text" />
                        </div>
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.packagePrice">Monthly Price is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="p_type">Package Type*</label>
                        <Dropdown v-model="addEditPackage.packageType" :options="packageModal.typeData" id="p_type" optionLabel="name" optionValue="uuid" placeholder="Select Type" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.packageType">Chose a Package type.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="p_feature">Package Features*</label>
                        <MultiSelect v-model="addEditPackage.featureName" id="p_feature" :options="packageModal.featureArray" optionLabel="value" placeholder="Select Features" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.packagefeatureName">Chose at-leat a Feature.</small>
                        <div v-if="addEditPackage.featureName" class="sup_feature_given">
                            <h4>Active Menus Privilege</h4>
                            <ul>
                                <li v-for="(feature, i) of addEditPackage.featureName" :key="i"><i class="pi pi-check-circle"></i>{{feature.value}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="p_desc">Package Description</label>
                        <Editor v-model="addEditPackage.description" id="p_desc" editorStyle="height: 200px"/>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="p_shrt_desc">Package Short Description</label>
                        <Editor v-model="addEditPackage.shortDescription" id="p_shrt_desc" editorStyle="height: 100px"/>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Add" class="btn-primary p-mr-2 sup_submit" />
                    <Button type="button" label="Reset" class="p-button-dark sup_submit" @click="resetForm()" />
                </div>
                <div :class="['sup_form_massage p-mt-3', allPackageData.addStatus === 200 ? 'success' : 'error']" v-if="allPackageData.addStatus !== ''">{{allPackageData.addMessage}}</div >
            </form>
        </Dialog>
        <!--- Add Package Modal End --->
        <!--- Edit Package Modal Start --->
        <Dialog v-model:visible="packageModal.editPackageModal" :modal="true" :style="{width: '800px'}" @hide="closeModal()">
            <template #header>
                <span class="p-dialog-title">Edit {{packageModal.editPackagedata.packageName}} Package</span>
            </template>
            <form class="sup_package_add" @submit.prevent="updatePackage(packageModal.editPackagedata.uuid)">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="p_name">Package Name*</label>
                        <InputText v-model="addEditPackage.name" id="p_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.packageName">Name is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="p_hits">Page Views*</label>
                        <!-- {{addEditPackage.histList}}
                        {{packageModal.hitsData}} -->
                        <Dropdown v-model="addEditPackage.histList" :options="packageModal.hitsData" id="p_hits" optionLabel="hits" optionValue="hits" placeholder="Select Page Views" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.packageHistList">Chose a page View.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="p_custom">Set as Custom package</label>
                        <Dropdown v-model="addEditPackage.isCustom" :options="customPackage" id="p_custom" optionLabel="name" optionValue="value" placeholder="Select" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.isCustom">Chose if custom package or not.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="p_price">Monthly Price*</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">$</span>
                            <InputText v-model="addEditPackage.price" id="p_price" type="text" />
                        </div>
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.packagePrice">Monthly Price is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="p_type">Package Type*</label>
                        <Dropdown v-model="addEditPackage.packageType" :options="packageModal.typeData" id="p_type" optionLabel="name" optionValue="uuid" placeholder="Select Type" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.packageType">Chose a Package type.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="p_feature">Package Features*</label>
                        <MultiSelect v-model="addEditPackage.featureName" id="p_feature" :options="packageModal.featureArray" optionLabel="value" placeholder="Select Features" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.packagefeatureName">Chose at-leat a Feature.</small>
                        <div v-if="addEditPackage.featureName" class="sup_feature_given">
                            <h4>Active Menus Privilege</h4>
                            <ul>
                                <li v-for="(feature, i) of addEditPackage.featureName" :key="i"><i class="pi pi-check-circle"></i>{{feature.value}}</li>
                                <!-- <li v-for="i of packageModal.featureArray" :key="i"><i class="pi pi-check-circle"></i>{{i.value}}</li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="p_desc">Package Description</label>
                        <Editor v-model="addEditPackage.description" id="p_desc" editorStyle="height: 200px"/>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="p_shrt_desc">Package Short Description</label>
                        <Editor v-model="addEditPackage.shortDescription" id="p_shrt_desc" editorStyle="height: 100px"/>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Update" class="btn-primary p-mr-2 sup_submit" />
                    <Button type="button" label="Reset" class="p-button-dark sup_submit" @click="resetForm()" />
                </div>
                <div :class="['sup_form_massage p-mt-3', allPackageData.editStatus === 200 ? 'success' : 'error']" v-if="allPackageData.editStatus !== ''">{{'Package updatedation ' + allPackageData.editMessage + "."}}</div >
            </form>
        </Dialog>
        <!--- Edit Package Modal End --->
        <!--- Deactive/Restore Confirmation Start --->
        <Dialog header="Confirmation" v-model:visible="confirmationModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="confirmationModal.modalState = false" class="p-button-text" autofocus/>
                <Button label="Yes" icon="pi pi-check" @click="deleteAndRestorePakage(confirmationModal.modaldata.data, confirmationModal.modaldata.load), confirmationModal.modalState = false" class="p-button-primary" />
            </template>
        </Dialog>
        <!--- Deactive/Restore Confirmation End --->   
    </div>
</template>

<script>
import { allPackage, packageHitList, packageType, packageFeatures, packageById } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import {currentMenuUuid} from '../../../../helper/pageActions/pageActions';
import axios from "axios";
import { useStore } from 'vuex';
import { reactive, onBeforeMount, ref, computed } from 'vue';

export default {
    name: 'PackageManagement',
    setup(){
        //Vuex store data call fucntion
        const store = useStore();

        //Page Privilages data state
        const pagePrivilages = reactive({
            userRole: '',
            uuid: '',
            actions: ''
        });

        //Package initial data storing state
        const allPackageData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'active',
            search: null,
            dateRange: null,
            addStatus: '',
            addMessage: '',
            editStatus: '',
            editMessage: '',
        });

        //Package modal state
        const packageModal = reactive({
            addPackageModal: false,
            editPackageModal: false,
            editPackagedata: '',
            hitsData: '',
            typeData: '',
            featureArray: []
        });

        //Custom package set option
        const customPackage = ref([
            {name: 'Yes', value: true},
            {name: 'No', value: false},
        ]);

        //Add/edit package form state
        const addEditPackage = reactive({
            name: '',
            histList: '',
            packageType: '',
            price: '',
            isCustom: false,
            featureName: null,
            description: '',
            shortDescription: ''
        });

        //Add/edit package validation state
        const addEditvalidationError = reactive({
            packageName: false,
            packageHistList: false,
            packageType: false,
            packagePrice: false,
            packagefeatureName: false,
            isCustom: false
        });

        //Delete/restore confimation state
        const confirmationModal = reactive({
            modalState: false,
            modaldata: '',
        });

        //Coditional page action state update to show action conditionally
        const packageAdd = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.packagemanagementAdd);
        const packageEdit = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.packagemanagementEdit);
        const packageDeactivate = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.packagemanagementDeactivate);
        const packageDeactivateList = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.packagemanagementDeactivateList);

        /**
         * Self calling fucntion on compement initiation
         */
        (async () => {
            let { userRole, menuActions } = await currentMenuUuid('PackageManagement');
            pagePrivilages.userRole = userRole;
            pagePrivilages.actions = menuActions;
        })();

        /**
         * On component get mounted require functions call
         */
        onBeforeMount(async() => {
            fetchAllPackages();
            fetchHitList();
            fetchType();
            fetchFeatures();
        });

        /**
         * Fetching all Packages from DB
         * intially fetching active Packages but this fucntion user can get deactive Package and filter Packages by date and name
         */
        const fetchAllPackages = async (activityStatus, load) =>{
            let date = null;
            //Toggle Package status to fetch active/deactive role
            if(load === 'packageStatus'){
                allPackageData.activityStatus = activityStatus === 'active' ? 'de-active' : 'active' ;
            }

            //To clear search and filter then fetch all data
            if(load === 'resetFilters'){
                allPackageData.search = null;
                allPackageData.dateRange = null;
            }

            //Filter date wise code
            if (load === 'dateRange') {
                date = formatDate(allPackageData.dateRange[0]) + ' ' + formatDate(allPackageData.dateRange[1]);
            }

            //API call start
            try{
                allPackageData.loading = !allPackageData.loading;
                const response = await axios.get( allPackage, {
                    params: {
                        status: allPackageData.activityStatus,
                        search: allPackageData.search,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                allPackageData.data = response.data.data.packages;
                allPackageData.total = response.data.data.total;
                allPackageData.loading = !allPackageData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        /**
         * Fetch all packages hits
         */
        const fetchHitList = async () => {
            try{
                const response = await axios.get( packageHitList, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                packageModal.hitsData = response.data.data.packageHits;
            } catch(err){
                console.log('error', err);
            }
        }

        /**
         * Fetching package type list ans storing on local state
         */
        const fetchType = async () => {
            try{
                const response = await axios.get( packageType, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                packageModal.typeData = response.data.data.packagesType;
            } catch(err){
                console.log('error', err);
            }
        }

        /**
         * Fetching feature list to set on package
         */
        const fetchFeatures = async () => {
            try{
                const response = await axios.get( packageFeatures, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                for (const key in response.data.data.features) {
                    packageModal.featureArray.push(response.data.data.features[key]);
                }
            } catch(err){
                console.log('error', err);
            }
        }

        /**
         * Create new package
         * On success again fetch package list and reset form
         */
        const createPackage = async () =>{
            let isValidate = formValidation();
            if (isValidate) {
                let NewFeatureList = {};
                for (const key in addEditPackage.featureName) {
                    NewFeatureList[key] = addEditPackage.featureName[key];
                }
                
                //API call start
                try{
                    const response = await axios.post( allPackage, {
                        packageName: addEditPackage.name,
                        price: addEditPackage.price,
                        shortDescription: addEditPackage.shortDescription,
                        longDescription: addEditPackage.description,
                        packageTypeUUID: addEditPackage.packageType,
                        packageFeatures: NewFeatureList,
                        hits: addEditPackage.histList,
                        isCustom: addEditPackage.isCustom,
                    }, { headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken,
                        },
                    });
                    if(response.status === 200) {
                        allPackageData.addStatus = response.data.status;
                        allPackageData.addMessage = response.data.data;
                        
                        fetchAllPackages('active');
                        packageFormReset();
                    }
                } catch(err){
                    console.log('err', err);
                }
            }
        }

        /**
         * Edit package of a specific item
         * @param {slotData} getting Unique id of the package
         * on success update local states with API respose
         */
        const editPackage = async (slotData) =>{
            const response = await axios.get( packageById, {
                params: {
                    uuid: slotData.uuid,
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if (response.data.status === 200) {
                let featureListArray = [];
                let _featureArr = JSON.parse(response.data.data.features);
                for (const key in _featureArr) {
                    featureListArray.push(_featureArr[key]);
                }

                packageModal.editPackagedata = slotData;
                addEditPackage.name = response.data.data.packageName,
                addEditPackage.histList = response.data.data.hits,
                addEditPackage.packageType = response.data.data.Packagetype.uuid,
                addEditPackage.price = response.data.data.price,
                addEditPackage.isCustom = response.data.data.isCustom,
                addEditPackage.featureName = featureListArray,
                addEditPackage.description = response.data.data.details,
                addEditPackage.shortDescription = response.data.data.shortDetails;
                packageModal.editPackageModal = true;
            }

        }

        /**
         * Update package after edit
         * @param {uuid} comes with unique id
         */
        const updatePackage = async (uuid) =>{
            
            let isValidate = formValidation();
            if (isValidate) {
                let NewFeatureList = {};
                for (const key in addEditPackage.featureName) {
                    NewFeatureList[key] = addEditPackage.featureName[key];
                }
                
                //API starts
                try{
                    const response = await axios.put( allPackage, {
                        packageName: addEditPackage.name,
                        price: addEditPackage.price,
                        shortDescription: addEditPackage.shortDescription,
                        longDescription: addEditPackage.description,
                        packageTypeUUID: addEditPackage.packageType,
                        packageFeatures: NewFeatureList,
                        hits: addEditPackage.histList,
                        isCustom: addEditPackage.isCustom,
                    }, {
                        params: {
                            uuid: uuid,
                        },
                        headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken,
                        },
                    });
                    if(response.status === 200) {
                        allPackageData.editStatus = response.data.status;
                        allPackageData.editMessage = response.data.message;
                        fetchAllPackages('active');
                    }
                } catch(err){
                    allPackageData.editStatus = err.response.data.status;
                    allPackageData.editMessage = err.response.data.message;
                }
            }
        }

        //edit/add form validation
        const formValidation = () => {
            if (!addEditPackage.name) {
                addEditvalidationError.packageName = true;
            } else {
                addEditvalidationError.packageName = false;
            }

            if (!addEditPackage.histList) {
                addEditvalidationError.packageHistList = true;
            } else {
                addEditvalidationError.packageHistList = false;
            }

            if (!addEditPackage.packageType) {
                addEditvalidationError.packageType = true;
            } else {
                addEditvalidationError.packageType = false;
            }

            if (!addEditPackage.price) {
                addEditvalidationError.packagePrice = true;
            } else {
                addEditvalidationError.packagePrice = false;
            }

            if (!addEditPackage.featureName) {
                addEditvalidationError.packagefeatureName = true;
            } else {
                addEditvalidationError.packagefeatureName = false;
            }

            if (addEditPackage.isCustom === 0) {
                addEditvalidationError.isCustom = true;
            } else {
                addEditvalidationError.isCustom = false;
            }

            if (!addEditvalidationError.packageName && !addEditvalidationError.packageHistList && !addEditvalidationError.packageType && !addEditvalidationError.packagePrice && !addEditvalidationError.packagefeatureName && !addEditvalidationError.isCustom) {
                return true;
            } else {
                return false;
            }
        }

        //form reset
        const packageFormReset = () => {
            addEditPackage.name = '',
            addEditPackage.histList = '',
            addEditPackage.packageType = '',
            addEditPackage.price = '',
            addEditPackage.isCustom = '',
            addEditPackage.featureName = '',
            addEditPackage.description = '',
            addEditPackage.shortDescription = ''
        }

        /**
         * Delete and restore action confirmation
         * @param {sloteData} update confirmation of a specific data which going to delete on accept
         * @param {load} comes with action state
         */
        const confimationModal = (sloteData, load) => {
            confirmationModal.modalState = true;
            confirmationModal.modaldata = {
                data: sloteData,
                load: load
            }
        }

        /**
         * Delete and restore package function
         * @param {sloteData} comes with unique id
         * @param {load} comes with action state
         */
        const deleteAndRestorePakage = async (sloteData, type) => {
            //API starts
            try{
                const options = {
                    method: type === 'delete' ? 'delete' : 'patch',
                    url: (allPackage) + (type === 'delete' ? '/delete' : '/restore'),
                    params: {
                        uuid: sloteData.uuid
                    },
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                await axios(options);
                fetchAllPackages('active');
            } catch(err){
                console.log(err);
            }

        }

        //Filtering date format
        const formatDate = (date) => {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        }

        //All modal after close function call
        const closeModal = () => {
            packageFormReset();
            allPackageData.addStatus= '',
            allPackageData.addMessage= '',
            allPackageData.editStatus = '';
            allPackageData.editMessage = '';
        }

        //Returing variables to template
        return{
            packageAdd,
            packageEdit,
            packageDeactivate,
            packageDeactivateList,
            allPackageData,
            packageModal,
            addEditPackage,
            addEditvalidationError,
            customPackage,
            confirmationModal,
            fetchAllPackages,
            createPackage,
            editPackage,
            updatePackage,
            closeModal,
            confimationModal,
            deleteAndRestorePakage
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/style/component/superAdmin/pages/package/PackageList";
</style>